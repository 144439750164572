/*
light: #E0FCFF
black: #617D98
dark: #2CAEBA 
dark2: #289DA8
dark blue: #102A42
bg color : #F1F5F8
text: #425B73
*/
body {
  font-family: "Roboto Slab", serif;
}

.header {
  display: flex;
  width: 100%;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px;
}
.right {
  display: flex;
  width: 30rem;
  height: 80px;
  margin-right: auto;
  margin-left: 8px;
  padding-top: 5px;
}
.right a {
  display: inline-block;
  height: 10rem;
  display: inline-block;
  position: relative;
  z-index: 1;
  margin-top: 1.5rem;
}
.right-e {
  font-family: "Roboto Slab", serif;
  margin: 10px;
  font-size: 18px;
  font-weight: 500;
  color: #102a42;
  margin-right: 8px;
  padding: 16px 5px;
  white-space: nowrap;
  letter-spacing: 2px;
}

.right-e:hover {
  color: #2caeba;
  border-bottom: 3px solid #2caeba;
  transition: 0.15s;
}

/*logo*/
.left {
  font-family: "Roboto Slab", serif;
  margin-left: 10rem;
  margin-top: 1.5rem;
}
.left h3 {
  color: #102a42;
  margin-right: 1rem;
  font-size: 30px;
  font-weight: 600;
}
.left h4 {
  color: #2caeba;
  font-size: 30px;
  font-weight: 500;
}
.left a {
  display: inline-block;
  position: relative;
  width: 16rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  padding: 2em;
  margin: -2em;
}
