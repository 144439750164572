/*
light: #E0FCFF
black: #617D98
dark: #2CAEBA 
dark2: #289DA8
dark blue: #102A42
bg color : #F1F5F8
text: #425B73
*/
body {
  font-family: "Roboto Slab", serif;
}
.project {
  width: 100%;
  height: 420vh;
  justify-content: center;
  background-color: #e0fcff;
  /* margin-top: -20px; */
}
.upper {
  width: 100%;
  height: 8rem;
  padding-top: 3rem;
}
.upper h1 {
  text-align: center;
  width: 16rem;
  margin: 0 auto;
  font-size: 50px;
  font-weight: bold;
  color: #102a42;
  margin-bottom: 10px;
  font-family: "Roboto Slab", serif;
}
.line {
  border-bottom: solid 7px #2caeba;
  margin: 5px auto;
  width: 8rem;
}

.item {
  display: flex;
  margin: 4.5rem auto;
  justify-content: center;
}
.pic {
  width: 48rem;
}
.i-pic {
  width: 48rem;
  height: 27rem;
  border-radius: 7px;
}
.data {
  width: 45rem;
  height: 21rem;
  background-color: #fff;
  margin-top: 3.5rem;
  margin-left: -23rem;
  z-index: 1;
  box-shadow: 0 6px 8px 0 rgba(16, 42, 66, 0.5),
    0px 6px 10px 0px rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}
.data h2 {
  color: #102a42;
  font-size: 35px;
  font-weight: 550;
  margin: 1.5rem 2rem 1rem 2rem;
  font-family: "Roboto Slab", serif;
}
.data p {
  color: #425b73;
  font-size: 18px;
  margin: 1rem 2rem 1.5rem 2rem;
  text-align: justify;
  font-family: "Roboto Slab", serif;
}
